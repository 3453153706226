@charset "utf-8";



// Our variables
$base-font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: white;
$brand-color:      #ff8000;
$brand-secondary-color: #4A9FB7;
$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$home-width: 1024px;
$home-width-on-palm: 600px;
$home-width-on-laptop: 800px;
$content-width:    800px;
$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;


h1, h2, h3, h4, h5, h6, body {
  font-weight: medium;
  line-height: 1.6em;
  font-size: 17px;
  font-family: $base-font-family;
}


.home a {
  color: black;
  text-decoration: none;

  &:hover {
    color: $brand-color;
    text-decoration: none;
  }
}

.home a img {
  transition: transform .1s;
}

.home a:hover img {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  transform: scale(1.05);
}

.main-post .post-image {
  max-width: 1000px;
  height: 400px;
  border-radius: 5px;
  object-fit: cover;
  overflow: hidden;
}

.main-post .post-image-wrapper {
  flex: 2;
  flex-basis: 66.67%; /* 2/3 of the width */
}

.main-post .post-details {
  flex: 1;
  flex-basis: 33.33%; /* 1/3 of the width */
}

.post-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 40px;
  background-color: #fff;
  color: #444;
}

@media (max-width: $on-laptop) {
  .post-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: $on-palm) {
  .post-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}


.main-post {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
}

@media (max-width: $on-palm) {
  .main-post {
    display: block;
  }

  .main-post .post-title {
    font-size: 25px;
    line-height: 1.25;
    margin-top: 0px;
  }

  .main-post .post-image {
    height: 200px;
  }
}

.post-image {
  max-width: 1000px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  overflow: hidden;
}

.post-image.post-image-fit {
  width: 90%;
  padding: 10px;
  object-fit: contain;
}

.post-grid-box .post-image-fit {
  height: 180px;
}

.post-image-wrapper {
  background-color: #FFEEE6;
  border-radius: 5px;
  overflow: hidden;
}

.post-description {
  margin-top: 10px;
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

h1, .post-title, .post-link {
  font-size: 35px;
  line-height: 1.2;
  margin-top: 0px;
}

.home .main-post .post-title {
  font-size: 35px;
  line-height: 1.1;
  margin-top: 0px;
}


.home .post-title {
  font-size: 25px;
  line-height: 1.25;
  margin-top: 0px;
}

h2, .post-content h2 {
  font-size: 25px;
  line-height: 1.25;
}

.related-posts h2 {
  margin-top: 0px;
}

h3, .post-content h3 {
  font-family: 'Futura', 'Helvetica Neue';
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
}


body {
}

strong {
  font-weight: 600;
}

pagination {
  text-align: center;
}

.wrapper {
  margin-top: 50px;
}

.container {
  background-color: white;
  margin: 0 auto;
  max-width: 1024px;
  padding-bottom: 5px;
  padding-top: 0px;
  margin-top: 0px;
  padding: $spacing-unit;
}

@media (max-width: $on-palm) {
  .container {
    padding: 10px;
  }
}

@media (max-width: $on-laptop) {
  .container {
    padding: 10px;
  }
}



.navbar {
  margin: auto;
  max-width: 1200px;
  border: 0;
  background-color: none;
  background: none;
  background-image: none;

  line-height: 38px;
  padding: 14px;
}


.navbar ul {
  margin: 0px;
  display: inline-block;
}

.logo {
  height: 38px;
  margin-right: 50px;
  float: left;
}

.navbar li {
  line-height: 30px;
  list-style-type: none;
  display: inline;
  margin-left: 10px;
  color: rgb(153, 153, 153);
  font-size: 16px;
  font-weight: normal;
}

.navbar li a {
  color: #999;
}

.navbar li a:visited {
  color: #999;
}


.navbar li a.active {
  color: black;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.pagination-links {
  text-align: center;
  padding: 20px;
}

.tr-caption {
  font-size: 75%;
  line-height: 1.4em;
  text-align: center;
}

.fa {
  color: black;
}

.site-footer {
  text-align: center;
  border-top: 1px solid #e8e8e8;
}

.site-footer li {
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
}

.site-footer li a:hover {
  text-decoration: none;
}

.site-footer li a:hover i {
  color: gray;
  text-decoration: none;
}

.end-post {
  margin-top: 100px;
  margin-bottom: 100px;
}

.video-container {
  position: relative;
  padding-bottom: 50%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: $on-palm) {
  .post {
    width: 100%;
  }
}

@media (max-width: $on-laptop) {
  .post {
    width: 100%;
  }
}
