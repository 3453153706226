/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-top: $spacing-unit / 4;
    margin-bottom: $spacing-unit / 2;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit / 4;
}

/**
 * Images
 */

// Image caption
img {
  max-width: 300px; // For retrocompatibility
  margin: auto;
  display: block;
  border-radius: 5px;
}

.img {
  font-size: 75%;
  line-height: 1.4em;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 20px;

  margin-left: auto;
  margin-right: auto;
}

.img img {
  max-width: none;
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

// Large image
.img.large {
  max-width: 400px;
}

@media (max-width: 600px) {
  .img.large {
    max-width: 90%;
  }
}

// Giant image
.img.giant {
  max-width: 600px;
}

@media (max-width: 600px) {
  .img.giant {
    max-width: 90%;
  }
}

// Full width image
.img.full {
  max-width: none;
  width: 100%;
}

.img.float {
  float: left;
  margin: 10px;
}

.img.float.left {
  float: left;
}

.img.float.right {
  float: right;
}

@media only screen and (max-width: 600px) {
  .img.float {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .left {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.img.small {
  max-width: 200px;
}

.border {
  border: 0.5px solid lightgray;
}

/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Links
 */
a {
    color: $brand-color;
    font-weight: 500;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        text-decoration: underline dotted;
    }
}



/**
 * Blockquotes
 */
blockquote {
    line-height: 1.4em;
    font-style: italic;
    border-left: 3px solid #666;
    padding-left: 10px;
}

blockquote.large:before {
  position: absolute;
  font-size: 2em;
  color: $brand-secondary-color;
  line-height: 1em;
  content: open-quote;
  top: -0.08em;
  left: -0.05em;
}

blockquote.large:after {
  position: absolute;
  font-size: 2em;
  color: $brand-secondary-color;
  line-height: 1em;
  content: close-quote;
  bottom: -0.5em;
  right: -0.05em;
}

blockquote.large {
    position: relative;
    quotes: "“" "”";
    line-height: 40px;
    text-align: center;
    color: $brand-secondary-color;
    padding-left: 70px;
    padding-right: 70px;
    font-size: 40px;
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-left: none;
}


/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

.footnotes {
  margin-top: 40px;
  border-top: 1px solid #eee;
  font-size: 0.9em;
  line-height: 1.2em;
}

@media only screen and (max-width: 600px) {
  .footnotes {
    ol {
      margin-left: auto;
      margin-right: auto;
      list-style-position: inside;
    }
  }
}
